<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
    page() {
        return {
            title: this.title,
            meta: [{ name: "description" }],
        };
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Starter page",
            items: [
                {
                    text: "Utility",
                    href: "/",
                },
                {
                    text: "Starter page",
                    active: true,
                },
            ],
            loading: false,
            userinfo: [],
            micoapplist: {},
            activeName: "first"
        };
    },
    mounted() {
        this.userinfo = JSON.parse(localStorage.getItem("user"));
        this.title = this.$t("menuitems.icp.micoapp.info");
        this.items = [
            {
                text: this.$t("menuitems.micoapp.text"),
                href: "/",
            },
            {
                text: this.$t("menuitems.icp.micoapp.list"),
                href: "/micoapp",
            },
            {
                text: this.$t("menuitems.icp.micoapp.info"),
                active: true,
            },
        ];
        this.appid = this.$route.params.appid;
        this.getmicoappinfo();
    },
    methods: {
        getmicoappinfo() {
            var that = this;
            that.loading = true;
            that.$axios
                .post(
                    "https://api.aicoiot.com/micoappapi.php",
                    {
                        action: "micoapp.info",
                        appid: that.appid,
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.loading = false;
                    that.micoapplist = response.data.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        beforeAvatarUpload() {
            console.log('beforeAvatarUpload')
        },
        handleAvatarSuccess(res, file) {
            console.log('handleAvatarSuccess')
        },
        async showMicoappQrcode(qrcodelink) {
            try {
                // 获取图片数据
                const response = await fetch(qrcodelink);
                const blob = await response.blob();

                // 将Blob转换为Base64
                const base64String = await this.blobToBase64(blob);

                // 显示二维码图片
                this.$alert('<img src="' + base64String + '" style="width: 100%;">', '小程序码', {
                    dangerouslyUseHTMLString: true,
                    showConfirmButton: false,
                    showCancelButton: false,
                    closeOnClickModal: true,
                    closeOnPressEscape: true,
                });
            } catch (error) {
                console.error('图片下载或转换失败:', error);
            }
        },
        // Blob 转 Base64
        blobToBase64(blob) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        routeto(path) {
            this.$router.push({ path: path });
        }
    },
    filters: {
        formatDate(timestamp) {
            if (!timestamp || timestamp == 0) return '';
            // 将 Unix 时间戳从秒转换为毫秒
            const date = new Date(timestamp * 1000);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }
    }


};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body" v-loading="loading">
                        <el-form ref="micoapplist" :model="micoapplist" label-width="120px">
                            <el-form-item label="小程序名称">
                                <!-- <el-input disabled v-model="micoapplist.niconame"></el-input> -->
                                {{ micoapplist.niconame }}
                            </el-form-item>
                            <el-form-item label="小程序头像">
                                <img :src="micoapplist.head_img" class="avatar">
                                <!-- <el-upload class="avatar-uploader" action="#"
                                    :show-file-list="false" :on-success="handleAvatarSuccess"
                                    :before-upload="beforeAvatarUpload">
                                    <img v-if="micoapplist.head_img" :src="micoapplist.head_img" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload> -->
                            </el-form-item>
                            <el-form-item label="小程序APPID">
                                <!-- <el-input disabled v-model="micoapplist.appid"></el-input> -->
                                {{ micoapplist.appid }}
                            </el-form-item>
                            <el-form-item label="小程序码">
                                <!-- <el-link @click="showMicoappQrcode(micoapplist.qrcode)">查看小程序码</el-link> -->
                                <el-link :href="micoapplist.qrcode" target="_blank" type="primary">查看小程序码</el-link>
                            </el-form-item>
                            <el-form-item label="认证状态">
                                <el-tag v-if="micoapplist.realname == 0" type="danger">未认证</el-tag>
                                <el-tag v-else-if="micoapplist.realname == 1" type="success">已认证</el-tag>
                                <el-tag v-else-if="micoapplist.realname == 2" type="warning">认证中</el-tag>
                                <el-tag v-else-if="micoapplist.realname == 3" type="info">认证失败</el-tag>
                            </el-form-item>
                            <el-form-item label="认证过期时间">
                                {{ micoapplist.endtime | formatDate }}
                            </el-form-item>
                            <el-form-item label="备案状态">
                                <el-tag v-if="micoapplist.icpstatus == 0" type="danger">未备案</el-tag>
                                <el-tag v-else-if="micoapplist.icpstatus == 1" type="success">已备案</el-tag>
                                <el-tag v-else-if="micoapplist.icpstatus == 2" type="warning">备案中</el-tag>
                                <el-tag v-else-if="micoapplist.icpstatus == 3" type="info">备案失败</el-tag>
                            </el-form-item>
                            <el-form-item label="备案号">
                                {{ micoapplist.icpcode }}
                            </el-form-item>
                        </el-form>
                        <el-tabs v-model="activeName" @tab-click="handleClick">
                            <el-tab-pane label="操作" name="first">
                                <el-button type="primary"
                                    @click="routeto('/micoapp/' + micoapplist.appid + '/menu')">菜单管理</el-button>
                                <el-button type="primary"
                                    @click="routeto('/micoapp/' + micoapplist.appid + '/charge')">充值管理</el-button>
                                <el-button type="primary"
                                    @click="routeto('/micoapp/' + micoapplist.appid + '/point')">积分设置</el-button>
                                <el-button type="primary"
                                    @click="routeto('/micoapp/' + micoapplist.appid + '/register')">注册设置</el-button>
                                <el-button type="primary"
                                    @click="routeto('/micoapp/' + micoapplist.appid + '/sale')">营销设置</el-button>
                                <el-button type="primary"
                                    @click="routeto('/micoapp/' + micoapplist.appid + '/home')">首页设置</el-button>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>